html {
	font-size: 100%;
	box-sizing: border-box;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
	sans-serif;
	font-size: 1rem;
	line-height: 1.5;
}

html,
body,
div#root {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

div.landing-page::after {
	content: "";
	background: url("/dday.jpg");
	background-size: cover;
	opacity: 0.3;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}

button:disabled {
	opacity: 0.5;
}

div.row {
	margin-left: 0;
	margin-right: 0;
}

div.row.top-row {
	min-height: 51vh;
	width: 100%;
}

div.row.bottom-row {
	min-height: 37vh;
	width: 100%;
}

div.row.fullscreen-row {
	min-height: 88vh;
	width: 100%;
}

div.row.header-row {
	min-height: 10%;
	width: 100%;
}

div.row.main-row {
	min-height: 70%;
}

div.row.log-list {
	min-height: 4rem;
}

div.container-fluid.top-level-container {
	height: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
}

div.container-fluid.content-container {
	flex-grow: 1;
	width: 100%;
	padding-left: 1vw;
	padding-right: 1vw;
}

nav.navbar.character-nav {
	line-height: normal;
	flex-grow: 0;
}

div.navbar-nav.nav-tabs a {
	padding: .5rem;
}

div.content-panel {
	height: auto;
	border: .25rem solid rgba(164, 164, 164, .5);
	border-radius: .25rem;
}

div.full-width {
	width: 100%;
}

div.card.subpanel {
}

div.scrollable {
	overflow-y: scroll;
}

div.list-group.scrollable {
	max-height: 65vh;
}

.sub-scrollable {
	max-height: 20%;
}

.header-height {
	min-height: 15%;
}

.main-content-height {
	min-height: 85%;
}

.full-height {
	height: 100%;
}

div.scrollable.full-height {
	max-height: 100%;
}

div.max-h-100 {
	max-height: 100%;
}

div.max-h-85 {
	max-height: 85%;
}

div.card.m-medium {
	margin: 1rem;
}

.log>.list-group-item {
	padding: 0;
	font-size: .8rem;
}

table.detail-table td {
	padding-top: 0;
	padding-bottom: 0;
}

.roll-result.success {
	color: darkgreen;
}

.roll-result.success.critical {
	text-shadow: 2px 2px 2px lawngreen;
}

.roll-result.failure {
	color: darkred;
}

.roll-result.failure.critical {
	text-shadow: 2px 2px 2px red;
}

.react-select {
	width: 80%;
	min-width: 10rem;
	max-width: 25rem;
	margin: .5rem;
}

.list-picker {
	width: 80%;
}

input.number-picker {
	width: 3rem;
}

input.number-picker.wide {
	max-width: 6rem;
	min-width: 3rem;
}

div.form-group.column-centered {
	display: flex;
}

div.form-group.no-margin {
	margin: 0;
}

svg.status-icon {
	margin: .1em;
}

div.card.selected {
	background-color: cornflowerblue;
}

div.flex-textbox {
	width: 20em;
	flex-grow: .4;
}

@font-face {
	font-family: "Army Rust";
	src: url("/army-rust.ttf") format("truetype");
}

.stencil-font {
	font-family: 'Army Rust', 'serif';
	font-size: 1.5rem;
}

.stencil-font.header {
	font-size: 2.5rem;
	letter-spacing: .15rem;
}

h1 {
	font-family: 'Army Rust', 'serif';
	font-size: 4rem;
	letter-spacing: .08rem;
}

h2 {
	font-family: 'Army Rust', 'serif';
	font-size: 3rem;
	letter-spacing: .08rem;
}

h4 {
	font-family: 'Army Rust', 'serif';
	font-size: 2rem;
	letter-spacing: .08rem;
}

p.disclaimer {
	text-align: center;
	font-size: .8rem;
}
